.filter-options{
  max-height: 67%;
  height: 100%;
  overflow-y: auto;

  .facet-group{

    ion-checkbox{
      margin-right: 11px;
      margin-top: 0;
      margin-bottom: 0;
    }

    ion-label{
      margin: 0;
    }

    ion-item{
      --min-height: 33px;
      // padding-bottom: 14px;
    }

    h3{
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      padding-top: 19px;
      padding-bottom: 19px;
      cursor: pointer;
      line-height: 16px;

      ion-icon{
        display: inline-block;
        vertical-align: top;
        float: right;
      }
    }

    .options{
      transition: all .5s;
      height: auto;
      max-height: 0;
      overflow: hidden;
    }
  }
}

.filter-actions{
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0px;
  padding-left: 24px;
  padding-right: 24px;

  ion-button{
    width: 100%;
    text-transform: none;
    font-size: 14px;
  }

  ion-button::part(native){
      padding-left: 0;
      padding-right: 0;
      border: 1px solid black;
      box-shadow: none;
  }
}

.plt-ios{

  .filter-actions{
    bottom: 70px;
  }
}