.pwa-modal{
    --border-radius: 16px;
    --backdrop-opacity: .48;
    --ion-backdrop-color: #1F2937;
    //--background: #1F2937;

    // &::part(backdrop){
    //     background-color: #1F2937;
    //     opacity: .48;
    // }

    ion-toolbar{
        padding-top: 16px !important;
        --background: transparent;
    }

    ion-input{

        input{
            padding-left: 13px !important;
        }
    }

    &.sort{
  
        .filter-action-sheet{
          padding: 10px;
          max-height: 340px;
          overflow: scroll;
          position: relative;
      
          ion-item{
              cursor: pointer;
          }
      
          .title{
              position: relative;
              font-weight: bold;
              padding-top: 10px;
              padding-bottom: 24px;
              
              p{
                  margin: 0;
              }
      
              .close-icon{
                  position: absolute;
                  top: 0;
                  right: 0;
                  cursor: pointer;
              }
          }
      
          &.facet-action-sheet{
              padding: 24px;
              height: 100vh;
              max-height: 80vh;
      
              .title{
                  padding-bottom: 0;
              }

              .search-field{
                padding-left: 0;
                padding-right: 0;
              }
          }
      }
    }
  
    &.warning{
  
        h2{
            color: #DA1414;
        }
  
        .text {
            font-size: 16px;
            font-weight: 600;
            margin-top: 16px;
            margin-bottom: 0;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
  
    &.remove{
  
        h2{
            color: #DA1414;
        }
    }
  
    &.drop-ship{
  
        .drop-ship-actions{
            position: sticky;
            bottom: 0;
            left: 0;
            z-index: 99;
            height: 124px;
            width: 100%;
            padding: 24px;
            background-color: #F6F6F8;
  
            ion-button{
                width: 100%;
                text-transform: none;
    
                &::part(native){
                    border: 1px solid #000;
                    border-radius: 20px;
                    padding: 8px 24px;
                    height: 34px;
                    font-size: 12px;
                }
  
                &.button-disabled{
  
                    &::part(native){
                        border: none;
                    }
                }
            }
        }
  
        .address-form{
            padding: 24px;
            padding-top: 0;
            position: relative;
    
            .input-wrapper{
                margin-bottom: 20px;
  
                ion-label{
                    font-size: 14px;
                    color: #57595A;
                    font-weight: 400;
                    margin-bottom: 8px;
                    display: block;
                }
            }
    
            ion-select{
                margin-top: 10px;
            }
    
            ion-input {
                border: 1px solid #989B9C;
                border-radius: 8px;
                color: #000;
                margin-top: 10px;
    
                &.has-focus, &.has-value{
                    border-color: #000;
                }
                
                input{
                    padding-left: 13px;
                    height: 34px;
                }
            }
        }
  
        .error-text{
  
            p{
                font-size: 14px;
                font-weight: 400;
                color: #DA1414;
                line-height: 21px;
                margin-top: 0;
            }
        }
    }
  
    h2{
        color: #000;
        font-weight: 600;
        font-size: 20px;
        padding-left: 24px;
        margin: 0;
    }
  
    .choices{
  
        ion-router-link{
            width: 100%;
            display: block;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
  
            &.selected{
                font-weight: bold;
            }
        }
  
        ion-item{
            cursor: pointer;
  
            ion-router-link{
                width: auto;
                display: initial;
                padding-left: 9px;
            }

            ion-icon{
                margin-right: 2px;
            }
        }
    }    
}