.product-list{

  .custom-button{
    width: 100%;
    background-color: transparent;
    color: #000;
    border: 1px solid #000000;
    border-radius: 44px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 13px;
    padding-right: 13px;

    ion-icon{
      font-size: 20px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20px;
    }

    ion-text{
      line-height: 1.2;
      font-weight: 400;
      font-size: 14px;
      flex: 1 1 auto;
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      min-width: 0;
      min-height: 0;
      overflow: hidden;

      strong{
        max-width: none !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis;
        flex: 1;
        min-width: 0;
        min-height: 0;
      }

      span{
        display: inline-block;
        margin-right: 5px;
        width: 47px;
      }
    }

    &.has-filters{
      background-color: #000;
      color: #fff;
    }
  }

  .catalog-name{
    margin-bottom: 4px;
  }

  .padding-area{
    padding-left: 24px;
    padding-right: 24px;

    ion-title{
      padding: 0;
    }

    ion-breadcrumbs{
      padding-left: 0;
      padding-right: 0;
    }
  }

  .filters{
    padding-left: 19px;
    padding-right: 19px;

    ion-col{
      padding-top: 0;
    }

    ion-button{
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }

  ion-title{
    text-align: left;
    font-weight: 400 !important;
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 8px; 
    display: block !important;
    position: static !important;
    height: auto !important;
  }

  .result-text{
    margin-bottom: 16px;
    display: block;
    color: #57595A;
    font-size: 14px;
  }

  ion-col{
    ion-card-content{
      padding-top: 5px;
    }

    &:nth-child(odd){
      ion-card-content{
        padding-right: 0;
        padding-left: 8px;
      }
    }

    &:nth-child(even){
    
      ion-card-content{
        padding-left: 0;
        padding-right: 8px;
      }
    }
  }
}