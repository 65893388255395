.order{
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;

  &::part(native) {
    padding-top: 8px;
    padding-left: 0;
  }

  &::part(detail-icon) {
    display: none;
  }

  ion-label{
    margin-top: 0;
    margin-right: 0;

    h3{
      font-weight: 600 !important;
      font-size: 16px;
      line-height: 1.5;
      padding: 0;
      margin: 0;
    }

    ion-badge{
      margin-bottom: 0;
    }

    span{
      vertical-align: top;
      margin-left: 8px;
      line-height: 22px;
    }
  }

  ion-text{
    margin: 0;
    font-size: 14px;

    p{
      font-size: 14px;
      margin: 0;

      &.price{
        font-size: 16px;
        font-weight: 600;

      }
    }
  }

  ion-icon {
    margin-top: 0;
    margin-right: 0;
  }
}