.side-menu{

  .close-icon{
    cursor: pointer;
  }

  .menu-button{
    text-transform: none;
    font-size: 12px;
    font-weight: 600;
  }

  .large-item{
    font-size: 40px;

  }
  .list-container{
    margin-top: 100px;
  }
  .page-header{
    padding: 10px;
    display: flex;
  }
  ion-button{
    width: 100px;
    height: 45px;
    font-size: 12px;
    font-weight: 600;
  }
}